
import { Vue, Options } from 'vue-class-component';
import { LockOutlined, UserOutlined } from '@ant-design/icons-vue';
import { message } from 'ant-design-vue';
import axios from 'axios';
import router from '../router';

@Options({
    components: {
        'user-outlined': UserOutlined,
        'lock-outlined': LockOutlined
    }
})
export default class BoxedCategoryList extends Vue {
    isError: boolean = false;
    showModal = false;
    showModalEdit = false;
    modalMode : string = '';
    id = '';
    category_id = '';
    category_name = '';
    category_id_filter = '';
    category_name_filter = '';
    loading = false;
    columns = [
        {
            title: 'Category ID',
            dataIndex: 'category_id'
        },
        {
            title: 'Category Name',
            dataIndex: 'category_name',
        },
        {
            title: 'Options',
            dataIndex: 'options',
            slots: {
                customRender: 'viewMore'
            }
        },
    ];
    rows = [];
    pagination = {
        current: 1,
        pageSize: 100,
        total: 1
    };

    openCreateModal(mode: string) {
        this.showModal = true;
        this.modalMode = mode;
    }

    openEditModal(record: any, mode: string) {
        this.showModal = true;
        this.modalMode = mode;
        this.id = record.id;
        this.category_id = record.category_id;
        this.category_name = record.category_name;
    }

    openCreateLink(linkName: string) {
        try {
            router.push({
                name: linkName
            });
        } catch (error) {
            router.push({
                path: '/404'
            });
        }
    }

    async getTableData() {
        try {

            this.loading = true;
            let url = `${process.env.VUE_APP_API_URL!}/category-list-mapping?page=${this.pagination.current}`;

            if(this.category_id_filter != null && this.category_id_filter != undefined && this.category_id_filter != '') {
                url += `&category_id=${this.category_id_filter}`
            }

            if(this.category_name_filter != null && this.category_name_filter != undefined && this.category_name_filter != '') {
                url += `&category_name=${this.category_name_filter}`
            }

            const res = await axios.get(url, {
                headers: {
                    APIToken: `${window.localStorage.getItem(process.env.VUE_APP_ACCESS_TOKEN_IDENTIFIER!)}`
                }
            });
            const data = await res.data;
            if(data.error) {
                message.error(data.error_message);
            } else {
                data.boxed_categories.data.forEach((row: any) => {
                    row.key = row.id;
                    row.deleteLoading = false;
                });
                const currentPage = this.pagination.current;
                this.pagination = {
                    current: currentPage,
                    pageSize: 25,
                    total: data.boxed_categories.count
                };
                this.loading = false;
                this.rows = data.boxed_categories.data;
            }
        } catch (error) {
            console.log(error);
            message.error('Sorry, something went wrong. Please try again later.');
        }
    }

    async submit() {
        if(this.modalMode === 'create') {
            if (this.category_id === null || this.category_id === undefined || this.category_id.length <= 0) {
            message.error('Please enter category id!');
            return;
            }

            if (this.category_name === null || this.category_name === undefined || this.category_name.length <= 0) {
                message.error('Please enter category name!');
                return;
            }

            this.loading = true;

            try {
                const fd = new FormData();
                fd.append('category_id', this.category_id);
                fd.append('category_name', this.category_name);
                const res = await axios.post(`${process.env.VUE_APP_API_URL!}/category-list-mapping/create`, fd, {
                    headers: {
                        APIToken: `${window.localStorage.getItem(process.env.VUE_APP_ACCESS_TOKEN_IDENTIFIER!)}`
                    }
                });
                const data = await res.data;
                if(data.error) {
                    this.loading = false;
                    message.error(data.error_message);
                } else {
                    message.success('Category created successfully!');
                    this.showModal = false;
                    this.getTableData();
                }
            } catch (error) {
                this.loading = false;
                message.error('Sorry, something went wrong. Please check your internet connection and try again later.');
                return;
            }   
        } else {
            if (this.category_id === null || this.category_id === undefined || this.category_id.length <= 0) {
            message.error('Please enter category id!');
            return;
            }

            if (this.category_name === null || this.category_name === undefined || this.category_name.length <= 0) {
                message.error('Please enter category name!');
                return;
            }

            this.loading = true;

            try {
                const fd = new FormData();
                fd.append('id', this.id);
                fd.append('category_id', this.category_id);
                fd.append('category_name', this.category_name);

                const res = await axios.post(`${process.env.VUE_APP_API_URL!}/category-list-mapping/edit`, fd, {
                    headers: {
                        APIToken: `${window.localStorage.getItem(process.env.VUE_APP_ACCESS_TOKEN_IDENTIFIER!)}`
                    }
                });
                const data = await res.data;
                if(data.error) {
                    this.loading = false;
                    message.error(data.error_message);
                } else {
                    message.success('Successfully updated category!');
                    this.showModal = false;
                    this.getTableData();
                }
            } catch (error) {
                this.loading = false;
                message.error('Sorry, something went wrong. Please check your internet connection and try again later.');
                return;
            }
        }
    }

    handleTableChange(event: any) {
        const current = event.current;
        this.pagination.current = current;
        this.loading = true;
        this.getTableData();
    }

    async deleteRow(row:any) {
        if(confirm('Are you sure you want to delete this record?')) {
            row.deleteLoading = true;
            try {
                const fd = new FormData();
                fd.append('id', row.id);
                const res = await axios.post(`${process.env.VUE_APP_API_URL!}/category-list-mapping/delete`, fd, {
                    headers: {
                        APIToken: `${window.localStorage.getItem(process.env.VUE_APP_ACCESS_TOKEN_IDENTIFIER!)}`,
                        'Content-Type': 'application/json'
                    }
                });
                const data = res.data;
                row.deleteLoading = false;
                if(data.error) {
                    message.error(data.error_message);
                } else {
                    message.success('Successfully deleted row!');
                    this.getTableData();
                }
            } catch (error) {
                row.deleteLoading = false;
                console.log(error);
                message.error('Sorry, something went wrong. Please check your internet connection and try again later.');
            }
        }
    }

    mounted() {
        const token = window.localStorage.getItem(process.env.VUE_APP_ACCESS_TOKEN_IDENTIFIER!);
        if (token !== null && token !== undefined && token.length > 0) {
            router.push({
                name: 'boxed_category_list'
            });
        }
        this.getTableData();
    }
}
